@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');
body {
  font-family: 'Inter', sans-serif;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}


@layer base {
    :root {
      --background: 36 39% 88%;
      --foreground: 36 45% 15%;
      --primary: 36 45% 70%;
      --primary-foreground: 36 45% 11%;
      --secondary: 40 35% 77%;
      --secondary-foreground: 36 45% 25%;
      --accent: 36 64% 57%;
      --accent-foreground: 36 72% 17%;
      --destructive: 0 84% 37%;
      --destructive-foreground: 0 0% 98%;
      --muted: 36 33% 75%;
      --muted-foreground: 36 45% 25%;
      --card: 36 46% 82%;
      --card-foreground: 36 45% 20%;
      --popover: 0 0% 100%;
      --popover-foreground: 240 10% 3.9%;
      --border: 36 45% 60%;
      --input: 36 45% 60%;
      --ring: 36 45% 30%;
      --chart-1: 25 34% 28%;
      --chart-2: 26 36% 34%;
      --chart-3: 28 40% 40%;
      --chart-4: 31 41% 48%;
      --chart-5: 35 43% 53%;
    }
  
    .dark {
      --background: 36 39% 88%;
      --foreground: 36 45% 15%;
      --primary: 36 45% 70%;
      --primary-foreground: 36 45% 11%;
      --secondary: 40 35% 77%;
      --secondary-foreground: 36 45% 25%;
      --accent: 36 64% 57%;
      --accent-foreground: 36 72% 17%;
      --destructive: 0 84% 37%;
      --destructive-foreground: 0 0% 98%;
      --muted: 36 33% 75%;
      --muted-foreground: 36 45% 25%;
      --card: 36 46% 82%;
      --card-foreground: 36 45% 20%;
      --popover: 0 0% 100%;
      --popover-foreground: 240 10% 3.9%;
      --border: 36 45% 60%;
      --input: 36 45% 60%;
      --ring: 36 45% 30%;
      --chart-1: 25 34% 28%;
      --chart-2: 26 36% 34%;
      --chart-3: 28 40% 40%;
      --chart-4: 31 41% 48%;
      --chart-5: 35 43% 53%;
      }
  }
  
